<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header">
            <div slot="header">
              <i class="fa fa-th-large"></i><strong> Productions</strong>
              <info-message>
                Une production est le démarrage d'un cycle de croissance d'une culture.
                Nous présentons la liste de toutes les productions de votre exploitation
              </info-message>
              <div class="card-header-actions">
                <b-button variant="primary" class="createProd" :to="createProductionRoute">
                  <i class="fa fa-plus"></i>
                  Créer une production
                </b-button>
              </div>
            </div>
            
            <div class="text-center" v-if="fetchingProductionList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-tabs v-else-if="productions.length !=0" content-class="mt-3 border-0" v-model="productionTabOpen">
              <b-tab v-for="(tab,index) in tabs">
                <div slot="title" class="text-dark">
                  <div :id="'v-tab-'+index" class="text-dark">
                    <i :class="tab.icon"></i> {{tab.title}} ({{tab.productions.length}})
                  </div>
                </div>
                <b-row class="exploitations">
                  <p class="text-center" v-if="tab.productions.length == 0">Vous n'avez aucune production
                    {{tab.title}}.</p>
                  <template v-else>
                    <b-col md="6" lg="4" xl="4" v-for="production in tab.productions">
                      <b-card no-body>
                        <div slot="header">
                          {{production.name}}
                          <div class="card-header-actions">
                            <b-link href="#" class="card-header-action btn-setting">
                              <div v-if="production.runningHarvests.length !=0"
                                   class="d-inline-flex running-production">
                                &#8226;
                              </div>
                              <!--<i class="fa fa-shopping-cart"></i>-->
                              <i class="fa fa-balance-scale"></i>
                              <b-badge pill variant="danger" title="Récolte(s) en cours" v-b-tooltip.hover>
                                {{production.runningHarvests.length}}
                              </b-badge>
                              <b-badge pill variant="success" title="Récolte(s) planifiée(s)" v-b-tooltip.hover>
                                {{production.plannedHarvests.length}}
                              </b-badge>
                              <b-badge pill variant="success" title="Récolte(s) bloquée(s)" v-b-tooltip.hover>
                                {{production.blockedHarvests.length}}
                              </b-badge>
                            </b-link>
                          </div>
                        </div>
                        <b-list-group flush>
                          <b-list-group-item>
                            <i class="fa fa-clock-o mr-2 text-dark"></i>{{tab.timeTitle + ' ' + production.currentDate}}
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="icon-layers mr-2 text-secondary"></i>
                            Cultivée sur
                            <parcel-name @click="parcelUrl(production.parcel.id)">{{production.parcel.name}}
                            </parcel-name>
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="fa fa-leaf mr-2 text-success"></i>{{production.culturesToDisplay.join(' , ')}}
                          </b-list-group-item>
                        </b-list-group>
                        <b-card-body>
                          {{production.description}}
                          <br>
                        </b-card-body>
                        <div slot="footer" class="mt-2">
                          <b-button variant="success" class="mr-2" :to="production.url" v-b-tooltip.hover
                                    title="Ouvrir la production">
                            <i class="icon-size-fullscreen"></i>
                          </b-button>
                          <!--todo mettre le to pour la redirection-->
                          <b-button variant="secondary" class="mr-2"
                                    v-if="tab.type == 'planned' || tab.type == 'blocked'" v-b-tooltip
                                    :title="tab.type == 'blocked' ? 'Relancer la production':'Lancer la production'"
                                    @click="launchProduction(production,'save-start')">
                            <!--Lancer ou relancer une production-->
                            <template v-if="production.launching">
                              <b-spinner small class="mr-1"></b-spinner>
                              Lancement...
                            </template>
                            <i class="fa fa-play" v-else></i>
                          </b-button>
                          <b-button variant="secondary" class="mr-2" v-if="tab.type != 'planned'" v-b-tooltip.hover
                                    title="Tableau de bord" :to="production.url + '/dashboard'">
                            <!--Tableau de bord-->
                            <i class="fa fa-line-chart"></i>
                          </b-button>
                          <b-button variant="danger" class="mr-2" v-if="tab.type == 'running'" v-b-tooltip.hover
                                    title="Clôturer la production" @click="launchProduction(production,'close')">
                            <!--Cloturer une production-->
                            <template v-if="production.ending">
                              <b-spinner small class="mr-1"></b-spinner>
                              Clôture...
                            </template>
                            <i class="fa fa-ban" v-else></i>
                          </b-button>
                          <b-button variant="danger" class="mr-2" v-if="tab.type == 'abandoned'" v-b-tooltip.hover
                                    title="Supprimer la production">
                            <!--Supprimer une production-->
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                      </b-card>
                    </b-col>
                  </template>
                </b-row>
              </b-tab>
            </b-tabs>
            <p class="text-center" v-else>Vous n'avez crée aucune production.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Toast} from "../../../helpers";
  
  export default {
    name: "ExploitationProductions",
    title: "PIA - Productions",
    data() {
      return {
        fetchingProductionList: false,
        productions: [],
        error: null,
        productionTabOpen: 0,
  
        myOptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Quittez',
            buttonPrevious: 'Précédent',
            buttonNext: 'Suivant',
            buttonStop: 'Terminé'
          }
        },
      }
    },
    created() {
      this.fetchingProductionList = true
      Api.get('/exploitation/production/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            let isExistsProductionRunning = false
            let currentDate = ''
            this.productions = res.data.data.map(item => {
              switch (item.status.name) {
                case 'RUNNING':
                  isExistsProductionRunning = true
                  currentDate = item.startDate
                  break;
                case 'PLANNED':
                  currentDate = item.startDate
                  break;
                case 'TERMINATED':
                  currentDate = item.endDate
                  break;
                case 'BLOCKED':
                  currentDate = item.blockedDate
                  break;
                default:
                  currentDate = item.endDate
                  break;
              }
              return {
                ...item,
                url: '/exploitation/' + this.exploitationId + '/production/' + item.id,
                culturesToDisplay: item.cultures.map(culture => culture.name),
                currentDate: currentDate.split(' ').join(' à '),
                launching: false,
                ending: false,
                runningHarvests: item.harvests.filter(harv => harv.status.name == 'RUNNING'),
                plannedHarvests: item.harvests.filter(harv => harv.status.name == 'PLANNED'),
                blockedHarvests: item.harvests.filter(harv => harv.status.name == 'BLOCKED'),
              }
            })
            // to open a tab that has at least one production
            if (!isExistsProductionRunning && this.productions.length != 0)
              switch (this.productions[0].status.name) {
                case 'TERMINATED' :
                  this.productionTabOpen = 1
                  break
                case 'PLANNED' :
                  this.productionTabOpen = 2
                  break
                case 'ABANDONED' :
                  this.productionTabOpen = 3
                  break
                default :
                  this.productionTabOpen = 4
              }
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingProductionList = false
        })
    },
    watch: {
      error(e) {
        if (e) {
          Toast.error(e)
        }
      }
    },
    mounted(){
    
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      createProductionRoute() {
        return {
          name: 'ExploitationCreateProduction',
          params: {id: this.exploitationId}
        }
      },
      tabs() {
        return [
          {
            icon: 'fa fa-spinner',
            title: 'En cours',
            timeTitle: 'Débutée le',
            productions: this.productions.filter(item => item.status.name == 'RUNNING'),
            type: 'running'
          },
          {
            icon: 'fa fa-stop-circle',
            title: 'Terminées',
            timeTitle: 'Terminée le',
            productions: this.productions.filter(item => item.status.name == 'TERMINATED'),
            type: 'terminated'
          },
          {
            icon: 'fa fa-clock-o',
            title: 'Planifiées',
            timeTitle: 'Planifiée pour le',
            productions: this.productions.filter(item => item.status.name == 'PLANNED'),
            type: 'planned'
          },
          {
            icon: 'fa fa-th',
            title: 'Abandonnées',
            timeTitle: 'Abandonnée le',
            productions: this.productions.filter(item => item.status.name == 'ABANDONED'),
            type: 'abandoned'
          },
          {
            icon: 'fa fa-ban',
            title: 'Bloquées',
            timeTitle: 'Bloquée le',
            productions: this.productions.filter(item => item.status.name == 'BLOCKED'),
            type: 'blocked'
          },
        ]
      }
    },
    methods: {
      parcelUrl(parcel_id) {
        this.$router.push('/exploitation/' + this.exploitationId + '/parcels/create?edit=' + parcel_id)
      },
      async launchProduction(prod, state) {
        this.productions = this.productions.map(prodItem => {
          if (prodItem.id == prod.id)
            return {
              ...prodItem,
              launching: state == 'save-start',
              ending: state == 'close'
            }
          else return prodItem
        })
  
        if (state == 'save-start') {
  
          const res = await Api.post('/exploitation/production/parcel-status', {
            parcelId: prod.parcel.id
          })
  
          if (res.data.status === 'success' && res.data.data) {
            if (!res.data.data.canChoose) {
              Toast.error('La parcelle que vous avez choisie est en mode mono-prod. Il est cependant impossible d\'y lancer une nouvelle production ')
              return
            }
          }else {
            this.error = res.data.error
            return
          }
        }
        
        
        Api.post(`/exploitation/production/${state}`, {
          exploitationId: this.exploitationId,
          parcelId: prod.parcel.id,
          productionId: prod.id,
          startDate: prod.startDate,
          name: prod.name,
          description: prod.description,
          cultures: prod.cultures,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              const prodResult = res.data.data
              this.productions = this.productions.map(prodItem => {
                if (prodItem.id == prod.id)
                  return {
                    ...prodItem,
                    status: prodResult.status,
                    launching: false,
                    ending: false
                  }
                else return prodItem
              })
              if (state == 'save-start') {
                Toast.success('Production démarrée avec succès !')
                if (prodResult.status.name == 'RUNNING')
                  this.productionTabOpen = 0
                else
                  this.productionTabOpen = 4
              }
              else {
                Toast.success('Production clôturée avec succès !')
                this.productionTabOpen = 1
              }
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          
          })
      },
      nextComposantToVisit(){
        this.$router.push('/exploitation/' + this.exploitationId + '/production/harvests/list')
      }
    }
  }
</script>
<style scoped>
  parcel-name{
    font-weight: bold;
    font-size: 16px;
    font-family: "Verdana Pro Cond Black";
  }
  
  parcel-name:hover{
    color: #07C;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .running-production{
    font-weight: bold;
    color: #009000;
    margin-left: 5px;
    font-size: 103%;
    animation: runningAnimation 800ms linear infinite alternate;
    position: absolute;
    left: 5px;
  }
  
  @keyframes runningAnimation{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
</style>
